import React from "react";
import Hero from "./Hero";
import AboutPlap from "./AboutPlap";
import Tokenomics from "./Tokenomics";
import Teams from "./Teams";

// import WelcomeplayO from "./WelcomeplayO";
// import Delivers from "./Delivers";
// import Whychoose from "./Whychoose";
// import Partner from "./Partner";
// import ProductsCounting from "./ProductsCounting";
// import Blogs from "./Blogs";
// import Faqs from "./Faqs";
// import Header from "../layouts/Header";
// import Footer from "../layouts/Footer";
// import Marque from "./Marque";

const Home = () => {
  return (
    <div className="wrapper">
      <Hero />
      <AboutPlap />
      <Tokenomics />
      <Teams />





      {/* <WelcomeplayO/>
        <Delivers/>
        <Whychoose/>
        <Partner/> */}
      {/* <Marque/> */}
       {/* <Faqs/>  */}

      {/*  <ProductsCounting/>
        <Blogs/>*/}
    </div>
  );
};

export default Home;
