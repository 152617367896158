import { Diversity1Outlined } from "@mui/icons-material";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { IoCloseCircleOutline } from "react-icons/io5";
function AboutHypsoverse(props) {
  return (
    <Modal
      className="wallet-modal"
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <IoCloseCircleOutline onClick={props.onHide} />
      </Modal.Header>
      <Modal.Body className="text-start px-5">
        <p>
          Hypsoverse: Redefining Art Appreciation in the Digital Age Welcome to
          Hypsoverse.com, where art transcends boundaries and becomes an
          immersive experience like never before.
        </p>
        <h4>The Vision</h4>
        <p>
          At Hypsoverse, we are not just teleporting art lovers to distant
          virtual museums. We are taking them into the art itself. Imagine
          stepping into a painting, feeling the textures, and experiencing the
          emotions the artist intended. This is not just viewing art; it’s
          living it.
        </p>
        <h4>The Experience</h4>
        <p>
          When your avatar focuses on a picture, what you see in your headset is
          not just an image. You see the art in a new, tangible way. It’s as if
          you could touch it, enter it, and experience the painter’s
          imagination. It’s like being in an artificial dream where subtle
          emotions become intuitively available to the art enthusiast. This is
          the potential of digital art in the 21st century.
        </p>
        <h4>Beyond NFTs</h4>
        <p>
          We are not talking about mere NFTs. Hypsoverse requires both advanced
          equipment and innovative rendering techniques to bring art to life. We
          provide the hardware and software necessary to enhance an artist’s
          creation and make this extraordinary experience available to everyone.
        </p>
        <h4>The Future of Art Appreciation</h4>
        <p>
          Just as transportation has evolved with new equipment to make travel
          more efficient, art appreciation too requires new tools. The
          traditional ways of viewing two-dimensional surfaces and relying on
          scholars’ interpretations have reached their limits. With VR, we can
          experience art in a higher dimension, using new psychological
          techniques for rendering.
        </p>
        <h4>Why “Hypsoverse”?</h4>
        <p>
          The name “Hypsoverse” is derived from the combining Greek form
          “hypso-”, meaning “height” or “altitude.” This reflects our mission to
          elevate art appreciation to a higher dimension. In the Hypsoverse, art
          is not confined to flat surfaces; it reaches new altitudes of
          experience and emotion, allowing viewers to engage with art in a
          profoundly immersive and elevated way.
        </p>
        <h4>Join Us</h4>
        <p>
          We invite you to be a part of this revolutionary journey. Invest in
          Hypsoverse and help us bring this transformative experience to art
          lovers around the world. Together, we can redefine how art is
          appreciated and experienced.
        </p>
        <p>
          <Link to="https://hypsoverse.com/">Hypsoverse.com</Link> – Where Art
          Becomes an Experience.
        </p>
      </Modal.Body>
    </Modal>
  );
}

const AboutPlap = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <AboutHypsoverse show={modalShow} onHide={() => setModalShow(false)} />
      <section className="AboutPlap" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div data-aos="fade-up" data-aos-duration="800">
                <div className="Rewards">
                  <div className="txt">
                    <h4>Customizable Galleries</h4>
                    <p>
                      Customers can own personalized galleries within the
                      museum. They can curate and display artworks according to
                      their preferences and collections. Users can share
                      pictures of their physical galleries, and the museum's
                      technology will convert them into virtual galleries within
                      the metaverse.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div data-aos="fade-up" data-aos-duration="900">
                <div className="Rewards">
                  <div className="txt">
                    <h4>User-Uploaded Art:</h4>
                    <p>
                      Within their owned galleries, users can upload their own
                      artworks or pieces from their collection. This contributes
                      to a diverse range of art within the museum's virtual
                      space. The museum operates on a subscription-based system
                      for gallery uploads, allowing users to regularly add or
                      rotate artworks in their galleries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="Rewards">
                  <div className="txt">
                    <h4>NFT-Based Art Sales</h4>
                    <p>
                      The museum facilitates the buying and selling of artworks
                      as NFTs. Users can purchase NFTs of the displayed art
                      pieces directly from the platform, ensuring secure
                      ownership and transaction transparency. Artists retain
                      control over their art's pricing as NFTs and receive
                      proceeds from NFT sales, empowering them to monetize their
                      creations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="whatplap">
                <div className="img">
                  <img src="./images/whatplap.png" />
                </div>
                <div className="content">
                  <h4>What is XDALE?</h4>
                  <p>
                    Xdale is the trade pseudonym for Crosdale, Inc., a
                    pioneering entity in the world of digital innovation.
                    Crosdale, Inc. is the visionary force behind The Hypsoverse,
                    an immersive virtual reality (VR) platform that
                    revolutionizes the way we experience and interact with art.
                    The Hypsoverse is not just another digital space; it is a
                    meticulously crafted virtual museum designed to house an
                    ever-expanding collection of art from across the globe. This
                    platform provides artists, curators, and art enthusiasts
                    with a dynamic and interactive environment where creativity
                    meets cutting-edge technology.
                  </p>

                  <button onClick={() => setModalShow(true)} className="btn">
                    About Hypsoverse
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="rewardlist">
            <div
              class="listone"
              data-aos="fade-right"
              data-aos-duration="250"
              data-aos-offset="-150"
            >
              <div>
                <img src="/images/pwhite.png" className="mb-5" />
              </div>
              <h3>
                Harambe Is Set To Rock The Charts With a Whopping 100% ROI (This
                Year Alone!)
              </h3>
              <p>
                Each successful trade triggers the purchase and immediate
                burning of Harambe Tokens, increasing demand, reducing supply,
                and driving prices to the moon.
              </p>
              <div>
                <p>
                  <span>1 HARAMBE AI =</span> $1 USD
                </p>
              </div>
            </div>
            <div class="listtwo">
              <div
                data-aos="fade-up"
                data-aos-duration="250"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Hassle-Free Profits</h3>
                </div>
                <p>
                  No active trading needed! Simply hold the tokens and watch
                  your gains grow.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Hassle-Free Profits</h3>
                </div>
                <p>
                  No active trading needed! Simply hold the tokens and watch
                  your gains grow.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1400"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Hassle-Free Profits</h3>
                </div>
                <p>
                  No active trading needed! Simply hold the tokens and watch
                  your gains grow.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1600"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Hassle-Free Profits</h3>
                </div>
                <p>
                  No active trading needed! Simply hold the tokens and watch
                  your gains grow.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1800"
                data-aos-offset="-150"
                className="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Hassle-Free Profits</h3>
                </div>
                <p>
                  No active trading needed! Simply hold the tokens and watch
                  your gains grow.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                data-aos-offset="-150"
                class="listdata"
              >
                <div>
                  <img src="/images/recurring.svg" />
                  <h3>Hassle-Free Profits</h3>
                </div>
                <p>
                  No active trading needed! Simply hold the tokens and watch
                  your gains grow.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default AboutPlap;
