import invoke from '../utils/invoke';



export const saveTransaction = async (transactionData) => {
    const data = await invoke({ url: `/saveTransection`, method: 'POST', headers: {
        apikey: '1234',
    }, data: transactionData  });
    return data;
}


export const getContractInfo = async () => {
    const data = await invoke({ url: `/getContractInfo`, method: 'GET', headers: {
        apikey: '1234',
    }});
    return data;
}

