import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQ = () => {
  return (
    <div className="wrapper">
      <section className="HowWorks">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 pt-5 pt-lg-0">
              <div className="left-content">
                <h1>Frequently Asked Questions</h1>
              </div>
              <Accordion className="faq-accordion" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What security measures are in place to protect my
                    investment?
                  </Accordion.Header>
                  <Accordion.Body>
                    The platform employs blockchain technology, end-to-end
                    encrypted communication, smart contract audits, and
                    AI-driven threat detection to ensure the highest level of
                    security for your investments and transactions.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How does the Hypsoverse the Museum of Art integrate
                    Non-Fungible Tokens (NFTs)?
                  </Accordion.Header>
                  <Accordion.Body>
                    Artists can tokenize their artwork as NFTs. It allows users
                    to bid on to acquire unique digital art pieces. The
                    blockchain-backed system provides transparency and secure
                    ownership. Meanwhile, it empowers artists to benefit from
                    their sales.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    What makes Hypsoverse Metaverse Unique?
                  </Accordion.Header>
                  <Accordion.Body>
                    Our platform offers the best way to explore art globally. We
                    are breaking geographical barriers and providing exclusive
                    experience. AI-guided tours, avatar customization, and a
                    wide range of curated artwork are the features of the
                    Hypsoverse Museum of Art.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    How can I buy a $XER token?
                  </Accordion.Header>
                  <Accordion.Body>
                    To buy $XER, you have to participate in ICO. Instructions
                    are on the website. You can register and purchase XER tokens
                    with cryptocurrency. Make sure you read our legal
                    compliances to understand regulations.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>How $XER token is used?</Accordion.Header>
                  <Accordion.Body>
                    The XER Token is an ERC20-based digital asset. it is to
                    perform any transaction within the Hypsoverse Museum of Art.
                    Buying, selling, and bidding on digital artwork through it.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="col-lg-8 offset-lg-2 pt-5 pt-lg-0">
              <div className="left-content">
                <h1>Introduction to Art Museum Platform Metaverse</h1>
              </div>
              <Accordion className="faq-accordion" defaultActiveKey="5">
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    What is the Crosdale Museum of Art's virtual reality
                    platform?
                  </Accordion.Header>
                  <Accordion.Body>
                    The Crosdale Museum of Art introduces a revolutionary
                    virtual reality platform that redefines art experiences by
                    offering a non-physical environment for exploring and
                    appreciating art. Users can access a diverse collection of
                    artworks from the comfort of their homes.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    How does the museum's crowdfunding model work?
                  </Accordion.Header>
                  <Accordion.Body>
                    The museum operates on a crowdfunding model, inviting art
                    enthusiasts and supporters to contribute financially to the
                    platform's development and sustainability. Contributors
                    receive various benefits based on their level of support,
                    such as early access to new exhibits, exclusive virtual
                    events, or limited edition NFTs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    What is the Value-Added Art Reseller (VAR) target?
                  </Accordion.Header>
                  <Accordion.Body>
                    The VAR target involves curated art collections and enhanced
                    presentation features to provide a unique virtual art
                    experience.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    What are the curated art collections?
                  </Accordion.Header>
                  <Accordion.Body>
                    Curation Expertise: The museum focuses on curating diverse,
                    high-quality art collections, showcasing a mix of
                    established and emerging artists, various art styles,
                    mediums, and historical periods. Strategic Curation:
                    Thoughtful selection and arrangement of artworks to evoke
                    specific themes, emotions, or narratives within the virtual
                    space.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    How does the crowdfunding model support curated art
                    collections?
                  </Accordion.Header>
                  <Accordion.Body>
                    The museum uses crowdfunding to acquire original
                    masterpieces. Ownership is split 51:49 in favor of Crosdale,
                    Inc. Up to 4 individuals may collectively own 49%. Each
                    individual is authorized to mint NFTs and sell rights to
                    others, allowing financiers to recoup their investment.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    What are the enhanced presentation features?
                  </Accordion.Header>
                  <Accordion.Body>
                    Immersive User Interface: The VAR initiative prioritizes an
                    immersive, user-friendly interface, replicating the feel of
                    a physical art gallery through VR technology. Interactive
                    Features: Incorporating interactive elements to allow users
                    to explore and interact with artworks in a visually stunning
                    and intuitive environment.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="col-lg-8 offset-lg-2 pt-5 pt-lg-0">
              <div className="left-content">
                <h1>Re: Art Market Engagement</h1>
              </div>
              <Accordion className="faq-accordion" defaultActiveKey="11">
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    How does the VAR target maintain market relevance?
                  </Accordion.Header>
                  <Accordion.Body>
                    VAR targets maintaining a finger on the pulse of the art
                    market by adjusting collections, presentations, and features
                    to align with current art trends, market demands, and user
                    preferences.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    What is the value proposition of the museum?
                  </Accordion.Header>
                  <Accordion.Body>
                    The museum emphasizes its unique value proposition as a
                    destination for art enthusiasts, collectors, and buyers
                    seeking exceptional, curated collections and an immersive
                    art-buying experience.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="col-lg-8 offset-lg-2 pt-5 pt-lg-0">
              <div className="left-content">
                <h1>Re: Community Engagement</h1>
              </div>
              <Accordion className="faq-accordion" defaultActiveKey="13">
                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                    How does the VAR approach build community engagement?
                  </Accordion.Header>
                  <Accordion.Body>
                    The VAR approach aims to build a community around art
                    appreciation and investment by fostering engagement through
                    forums, events, and discussions, encouraging dialogue among
                    artists, collectors, and enthusiasts within the virtual
                    space.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>
                    How does the museum support artists?
                  </Accordion.Header>
                  <Accordion.Body>
                    The museum provides a platform for emerging artists,
                    offering exposure and opportunities to showcase their work
                    alongside established names in the art world. There will
                    also be incentives for repeat clients, such as discounted or
                    free headgear.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="col-lg-8 offset-lg-2 pt-5 pt-lg-0">
              <div className="left-content">
                <h1>Re: Sales and Monetization</h1>
              </div>
              <Accordion className="faq-accordion" defaultActiveKey="15">
                <Accordion.Item eventKey="15">
                  <Accordion.Header>
                    How does the museum facilitate art sales through NFTs?
                  </Accordion.Header>
                  <Accordion.Body>
                    The museum leverages NFT technology to facilitate art sales
                    within the virtual space, optimizing the platform for
                    secure, transparent, and efficient transactions for
                    purchasing art as NFTs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header>
                    How does the museum empower artists in the sales process?
                  </Accordion.Header>
                  <Accordion.Body>
                    The museum ensures that artists retain control over pricing
                    and receive fair compensation from the sales of their
                    artworks as NFTs, supporting their career growth and
                    financial stability.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-8 offset-lg-2 pt-5 pt-lg-0">
              <div className="left-content">
                <h1>Re: Overall Mission</h1>
              </div>
              <Accordion className="faq-accordion" defaultActiveKey="17">
                <Accordion.Item eventKey="17">
                  <Accordion.Header>
                    How does the VAR target align with the museum's mission?
                  </Accordion.Header>
                  <Accordion.Body>
                    The VAR target aligns with the museum's mission to be a
                    value-added entity in the art market by curating exceptional
                    collections, enhancing presentation, engaging with the art
                    market and community, and facilitating art sales through
                    NFTs. The museum aims to elevate the art-buying experience
                    and establish itself as a premier destination for art
                    enthusiasts and collectors in the virtual space.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="col-lg-8 offset-lg-2 pt-5 pt-lg-0">
              <div className="left-content">
                <h1>Details about Investment </h1>
              </div>
              <Accordion className="faq-accordion" defaultActiveKey="18">
                <Accordion.Item eventKey="18">
                  <Accordion.Header>What is my Opportunity?</Accordion.Header>
                  <Accordion.Body>
                    Investing in the Crosdale Museum of Art provides an
                    opportunity to support the growth of an innovative platform
                    at the intersection of art and technology. Contributors not
                    only assist in building a unique art space but may also
                    benefit from potential returns as the platform gains
                    traction and art sales generate revenue.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                  <Accordion.Header>
                    What are the details about Risk/Reward?
                  </Accordion.Header>
                  <Accordion.Body>
                    While the museum presents exciting opportunities, there are
                    inherent risks in any investment, including market
                    fluctuations, technological challenges, and evolving
                    consumer preferences. However, contributors have the
                    potential to reap rewards through the success of the
                    platform, increased art sales, and appreciation of NFTs.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="20">
                  <Accordion.Header>
                    What are the details about how legal compliance is met?
                  </Accordion.Header>
                  <Accordion.Body>
                    The Crosdale Museum of Art adheres to legal frameworks
                    governing virtual assets, NFT transactions, and crowdfunding
                    regulations. All transactions, contracts, and tokenization
                    processes comply with relevant laws to ensure transparency,
                    security, and legality for all involved parties.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="21">
                  <Accordion.Header>
                    What are the details about who gets paid and why?
                  </Accordion.Header>
                  <Accordion.Body>
                    Here's a breakdown of how artists can receive compensation
                    based on the sales of their physical inventory and its
                    derivatives (both physical and digital):
                  </Accordion.Body>
                  <Accordion.Body>
                    <h4>Physical Inventory Sales:</h4>- Lithographs and
                    Serigraphs: When artists sell physical reproductions of
                    their artwork, such as lithographs (printed copies using
                    stone plates) or serigraphs (silk-screened prints), they
                    receive compensation based on sales. This compensation
                    typically involves royalties or a predetermined percentage
                    of the sales revenue from these reproductions.
                  </Accordion.Body>
                  <Accordion.Body>
                    <h4>Digital Derivatives - NFT Sales:</h4> - NFT Creation:
                    Artists tokenize their original artwork as Non-Fungible
                    Tokens (NFTs), creating unique digital representations of
                    their pieces on the blockchain.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="col-lg-8 offset-lg-2 pt-5 pt-lg-0">
              <div className="left-content">
                <h1>About Me</h1>
              </div>
              <Accordion className="faq-accordion" defaultActiveKey="22">
                <Accordion.Item eventKey="22">
                  <Accordion.Header> Who am I?</Accordion.Header>
                  <Accordion.Body>
                    A New York entrepreneur with a degree in business
                    administration specializing in Marketing from a New York
                    university.
                  </Accordion.Body>
                  <Accordion.Body>
                    Decades of experience in various facets of sales and
                    marketing management.
                  </Accordion.Body>
                  <Accordion.Body>Experience includes:</Accordion.Body>
                  <Accordion.Body>
                    Telemarketing of Commodities Futures Derivatives on Wall
                    Street
                  </Accordion.Body>
                  <Accordion.Body>Retail store sales</Accordion.Body>
                  <Accordion.Body>SoHo NY Gallery Management</Accordion.Body>
                  <Accordion.Body>Direct Marketing</Accordion.Body>
                  <Accordion.Body>
                    Door-to-door sales of graphics to NY hotels
                  </Accordion.Body>
                  <Accordion.Body>
                    Personal Art creation samples (as seen at bitbrowze.com)
                  </Accordion.Body>
                  <Accordion.Body>I-T VAR sales, and more</Accordion.Body>
                  <Accordion.Body>Metaverse Platform Insights</Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="23">
                  <Accordion.Header>
                    {" "}
                    What are the features and functionalities of the Crosdale
                    Museum of Art?
                  </Accordion.Header>
                  <Accordion.Body>
                    <h4>Customizable Galleries:</h4>
                    Ownership: Customers can own personalized galleries within
                    the museum, curating and displaying artworks according to
                    their preferences and collections. Virtual Representation:
                    Users can share pictures of their physical galleries, which
                    the museum’s technology will convert into virtual galleries
                    within the metaverse.
                  </Accordion.Body>
                  <Accordion.Body>
                    <h4>User-Uploaded Art:</h4>
                    Gallery Upload: Users can upload their own artworks or
                    pieces from their collection within their owned galleries,
                    contributing to a diverse range of art within the museum’s
                    virtual space.
                  </Accordion.Body>
                  <Accordion.Body>
                    <h4>Subscription Model:</h4>
                    The museum operates on a subscription-based system for
                    gallery uploads, allowing users to regularly add or rotate
                    artworks in their galleries.
                  </Accordion.Body>
                  <Accordion.Body>
                    <h4>Segmented Sections:</h4>
                    User and Artist Segments: The museum’s virtual space
                    features distinct sections for random users and registered
                    artists. Artists can showcase their portfolios, providing a
                    platform for exposure and potential sales.
                  </Accordion.Body>
                  <Accordion.Body>
                    <h4>AI Integration for Art Description:</h4>
                    AI-Powered Information: An AI system integrated into the
                    museum’s VR experience provides information about the
                    artworks. When users interact with an artwork, their avatar
                    receives details about the piece, including the artist.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* <div className="col-lg-6 col-md-12">
              <div className="left-content">
                <h1>Frequently Asked Questions</h1>
                <h4>
                  <span>1</span>Set E Wallet
                </h4>
                <div className="content">
                  <h4>
                    <img src="/images/mobile.png" />
                    Install and Setup Metamask
                  </h4>
                  <p>
                    Download E-Wallet, follow the instructions to create a new
                    wallet or import an up-to-date existing one.
                  </p>
                  <h4>
                    <img src="/images/destkop.png" />
                    Fund your Metamask Wallet
                  </h4>
                  <p>
                  Click on "Add Funds" and choose the method to deposit ETH or USDT. You can transfer from another wallet or buy using a credit/debit card. 
                  </p>
                </div>
                <h4>
                  <span>2</span>Connect E Wallet with Hypsoverse
                </h4>
                <div className="content">
                  <p>
                  Click on "Connect Wallet" in the top right corner. Select the number of $XER tokens you want to buy from and the Hypsoverse. 
                  </p>
                  <p className="mb-5">
                  Follow the instructions to connect your E Wallet with Hypsoverse the Museum of Art. 
                  </p>
                </div>
                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Connect Wallet
                  </h5>
                  <ul>
                    <li>Look for top right connect wallet option</li>
                    <li>A prompt will appear with a list of wallets</li>
                    <li>Choose wallet you want to connect</li>
                  </ul>
                </div>
                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Verify Your Wallet Connection
                  </h5>
                  <ul>
                    <li>See your wallet address in the Hypsoverse dashboard</li>
                    <li>Go to profile to confirm that your wallet is linked</li>
                    <li>
                      Review any additional settings or information related to
                      your account and wallet
                    </li>
                  </ul>
                </div>
                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Participate in the $XER Coin Presale
                  </h5>
                  <ul>
                    <li>Navigate to the Presale Card section</li>
                    <li>Card will have all Presale information</li>
                    <li>Enter the amount of $XER you wish to buy</li>
                  </ul>
                </div>

                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Buy $XER Coins
                  </h5>
                  <ul>
                    <li>Click the Buy button</li>
                    <li>Confirm the transaction details</li>
                    <li>Check network fee </li>
                    <li> Confirm the transaction</li>
                    <li>Review the details carefully and Confirm</li>
                    <li>
                      Wait for the transaction processed on the blockchain and
                      this can take a few moments
                    </li>
                  </ul>
                </div>
                <div className="list">
                  <h5>
                    <img src="/images/square.png" />
                    Verify Your $XER Coin
                  </h5>
                  <ul>
                    <li>After the transaction go back to your wallet </li>
                    <li>Check your tokens </li>
                    <li>$XER coins should appear in your wallet</li>
                    <li>Use $XER contract address to add the token manually</li>
                    <li>
                      You will receive transaction history in Dashboard right
                      after My Wallet{" "}
                    </li>
                  </ul>
                </div>
                <h4>
                  <span>3</span>Hold & Reward
                </h4>
                <div className="content">
                  <p>
                  Once you have purchased the $XER coin, hold it in your wallet.
                  Hypsoverse offers additional earning opportunities such as Hold Coin to Earn More. 
                  </p>
                </div>
                <h4>
                  <span>4</span>Security
                </h4>
                <div className="content">
                  <p>
                  Always verify you are on the official Hypsoverse website and interacting with the correct smart contracts.
                  </p>
                </div>
                <h4>
                  <span>5</span>Transaction Fee
                </h4>
                <div className="content">
                  <p>
                    Be aware of network fees for transactions on Ethereum
                    and USDT
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1 col-md-12">
              <div className="right-content">
                <div className="imglist">
                  <img
                    src="/images/work1.png"
                    style={{ marginBottom: "164px", marginTop: "123px" }}
                    className="w-100"
                  />
                  <img
                    src="/images/work2.png"
                    style={{ marginBottom: "129px" }}
                    className="w-100"
                  />
                  <img
                    src="/images/work3.png"
                    style={{ marginBottom: "120px" }}
                    className="w-100"
                  />
                  <img
                    src="/images/work4.png"
                    style={{ marginBottom: "71px" }}
                    className="w-100"
                  />
                  <img
                    src="/images/work5.png"
                    style={{ marginBottom: "50px" }}
                    className="w-100"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQ;
