import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import MainLayout from "./MainLayout";
import HashModal from "../common/HashModal";
import { Modal } from "react-bootstrap";
import { useAccount, useSwitchChain } from "wagmi";
import { chains } from "../../chains";
import { IoCloseCircleOutline } from "react-icons/io5";
import { config } from "../../confiq";
import "../../assets/css/dashboard.css";

const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);

  const { chain, isConnected } = useAccount();

  const { switchChain } = useSwitchChain();

  useEffect(() => {
    if (isConnected && chains.findIndex((_) => _.id === chain?.id) === -1) {
      setShowModal(true);
    } else if (showModal) {
      setShowModal(false);
    }
  }, [chain?.id]);

  return (
    <>
      <div className="dashboardapp">
        <Sidebar />
        <MainLayout />
        {showModal && (
          <Modal
            show={showModal}
            backdrop="static"
            onHide={() => setShowModal(false)}
            className="wallet-modal"
            size="lg"
            style={{ maxWidth: "800px !important" }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {/* <Modal.Header >
              <Modal.Title style={{ color: 'white'}}>Switch Network</Modal.Title>
            </Modal.Header> */}
            {/* <Modal.Header>
              <IoCloseCircleOutline 
              onClick={props.onHide}
               />
            </Modal.Header> */}
            <Modal.Body className="p-5" style={{ maxWidth: "100% !important" }}>
              <div className="wallet-main referral-main">
                <h4>Switch Network</h4>

                {chains.map((chain) => (
                  <button
                    key={chain?.id}
                    className="header-button w-100 justify-content-center mb-3"
                    style={{ fontSize: "22px" }}
                    onClick={() => {
                      switchChain({ chainId: chain.id });
                    }}
                  >
                    {chain.name}
                  </button>
                ))}
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Dashboard;
