import React from "react";
import { useInView } from "react-intersection-observer";
const Tokenomics = () => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger once when the element comes into view
  });
  return (
    <>
      <section
        id="tokenomics"
        ref={ref}
        className={`Tokenomics ${inView ? "showcontent" : ""}`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="content">
                <div className="left-content">
                  <div className="txt">
                    <h4>Tokenomics</h4>
                    <p>
                      $XER is the new economy of art - tell us about the future
                      of making, owning, and selling art.
                    </p>
                  </div>
                  <ul className="list-inline">
                    <li className="ecommerce">
                      <div>
                        <h3>Marketing</h3>
                        <label>60%</label>
                      </div>
                      <div>
                        <h4>50M</h4>
                        <h5>Raised</h5>
                      </div>
                    </li>
                    <li className="partnership">
                      <div>
                        <h3>Maintenance</h3>
                        <label>30%</label>
                      </div>
                      <div>
                        <h4>12M</h4>
                        <h5>Raised</h5>
                      </div>
                    </li>
                    <li className="fund">
                      <div>
                        <h3>Advisors</h3>
                        <label>5%</label>
                      </div>
                      <div>
                        <h4>12M</h4>
                        <h5>Raised</h5>
                      </div>
                    </li>
                    <li className="advisory">
                      <div>
                        <h3>Launching</h3>
                        <label>5%</label>
                      </div>
                      <div>
                        <h4>5M</h4>
                        <h5>Raised</h5>
                      </div>
                    </li>
                    {/* <li className="growth">
                      <div>
                        <h3>Research & Development</h3>
                        <label>15%</label>
                      </div>
                      <div>
                        <h4>5M</h4>
                        <h5>Raised</h5>
                      </div>
                    </li>
                    <li className="reserve">
                      <div>
                        <h3>Marketing & Partnerships</h3>
                        <label>10%</label>
                      </div>
                      <div>
                        <h4>5M</h4>
                        <h5>Raised</h5>
                      </div>
                    </li> */}
                    {/* <li className="sale">
                      <div>
                        <h3>Public Sale</h3>
                        <label>20%</label>
                      </div>
                      <div>
                        <h4>1.6</h4>
                        <h5>Billion</h5>
                      </div>
                    </li> */}
                  </ul>
                </div>
                <div className="right-content">
                  <div className="circle-content">
                    <img src="/images/graphcircle.png" />
                    <div className="ecommerce">
                      <span>60%</span>
                      Marketing
                    </div>
                    {/* <div className="sale">
                      <span>20%</span>
                      Public Sale
                    </div> */}
                    <div className="reserve">
                      <span>5%</span>
                      Launching
                    </div>
                    <div className="growth">
                      <span>5%</span>
                      Advisors
                    </div>
                    <div className="advisory">
                      <span>30%</span>
                      Maintenance
                    </div>
                    {/*<div className="fund">
                      <span>15%</span>
                      Treasury
                    </div>
                     <div className="partner">
                      <span>20%</span>
                      Pre-Sale
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id="roadmap">
          <div className="row">
            <div className="col-12">
              <div className="Roadmap">
                <div className="image">
                  <img src="./images/roadmapback.png" />
                </div>
                <div className="roadmap-content">
                  <div className="heading">
                    <h4>Roadmap</h4>
                    <p>
                      The growth trajectory of Hypsoverse Coin XERIES ($XER),
                      from a startup to a premier cryptocurrency and Web 3.0
                      ecosystem, is explained in its roadmap.
                    </p>
                  </div>
                  <ul className="timeline list-inline">
                    <li
                      className="q1"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 1</h4>
                      <p>Development of Total Immersion coding:</p>
                      <p>
                        Creating the core technology for an immersive virtual
                        reality art experience.
                      </p>
                    </li>
                    <li
                      className="q2"
                      data-aos="fade-down"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 2:</h4>
                      <p>Value-Added Reseller commitment:</p>
                      <p>
                        Partnering with headgear manufacturers, art galleries,
                        and educational institutions to enhance the platform's
                        reach and functionality.
                      </p>
                    </li>
                    <li
                      className="q3"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 3:</h4>
                      <p>Recruitment of operating staff:</p>
                      <p>
                        Building a skilled team to manage and operate the
                        virtual art museum effectively.
                      </p>
                    </li>
                    <li
                      className="q4"
                      data-aos="fade-down"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 4:</h4>
                      <p>Deployment and Public Relations Announcements:</p>
                      <p>
                        Launching the platform to the public and generating buzz
                        through strategic PR campaigns.
                      </p>
                    </li>
                    {/* <li
                      className="q5"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <h4>Phase 5:</h4>
                      <p>
                        Introduce Social Metaverse
                      </p>

                      <p>
                        Implement cooperate sports & fitness partnership.
                      </p>

                      <p>
                        Add Additional Utilities.
                      </p>
                      
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tokenomics;
