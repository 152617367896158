import Countdown from "react-countdown";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import { Link, useNavigate } from "react-router-dom";
import { getContractInfo } from "../../api/api";
import millify from "millify";
import { TARGET_TOKEN_VALUE } from "../../confiq";
import ProgressBar from "react-bootstrap/ProgressBar";
import Skeleton from "react-loading-skeleton";
import { useAccount } from "wagmi";

const Hero = () => {

  const options = [
    { label: "USDT", value: "USDT", image: "/images/usdt-removebg-preview.png" },
    { label: "BNB", value: "BNB", image: "/images/bnb-bnb-logo.png" },
    {
      label: "WETH",
      value: "WETH",
      image: "/images/ethereum-logo-EC6CDBA45B-seeklogo.com.png",
    },
  ];

  const TokenPriceIndex = {
    USDT: 0,
    WBTC: 1,
    BNB: 1,
    WBNB: 1,
    WETH: 2,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [contractInfo, setContractInfo] = useState({ lastUpdateTime: null, soldToken: 0, prices: [] });
  const [inputValue, setInputValue] = useState();
  const navigate = useNavigate();

  const { isConnected } = useAccount();

  const fetchContractInfo = async () => {
    try {
      const { data } = await getContractInfo();
      setContractInfo(data);
    } catch (e) { }
  }

  useEffect(() => {
    fetchContractInfo();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const Completionist = () => <span>You are good to go!</span>;

  const calculatePercentage = () => {
    if (contractInfo.soldToken) {
      const num =
        (contractInfo.soldToken /
          TARGET_TOKEN_VALUE) *
        100;
      return num.toFixed(0);
    } else {
      return 0;
    }
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      return (
        <div className="d-flex mt-4 countdown justify-content-center">
          <div>
            <h1>{days}</h1>
            <h4>{["Days"]}</h4>
          </div>
          <div>
            <h1>{hours}</h1>
            <h4>{["Hours"]}</h4>
          </div>
          <div>
            <h1>{minutes}</h1>
            <h4>{["Minutes"]}</h4>
          </div>
          <div>
            <h1>{seconds}</h1>
            <h4>{["Seconds"]}</h4>
          </div>
        </div>
      );
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value.replace(/[^0-9.]/g, ''));
  };

  console.log('tester value ',
    contractInfo.prices[
      TokenPriceIndex[selectedOption.value]
    ]?.toString());

  return (
    <>
      <section className="Hero">
        <div className="Background">
          <div className="Rectangle-One" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="Hero-Content wm-md-100 gap-24">
                <div className="Content">
                  <div>
                    <h4>World's Best Online Art Museum is Open for visitors</h4>
                  </div>
                  <div>
                    <p>
                    Connect your wallet to invest in the worlds first immersive art museum
                    </p>
                  </div>
                </div>
                {/* <div className="middle-content mb-5">
                  <div className="pulselogo">
                    <img src="/images/pwhite.png" />
                  </div>
                  <div className="overflow">
                    <div className="anim-one">
                      <ul className="list-inline">
                        <li>
                          <img src="/images/footballhero.png" />
                          <h4>Football</h4>
                        </li>
                        <li>
                          <img src="/images/gridiron.png" />
                          <h4>Gridiron Football</h4>
                        </li>
                        <li>
                          <img src="/images/basketball.png" />
                          <h4>Basketball</h4>
                        </li>
                        <li>
                          <img src="/images/archer.png" />
                          <h4>Archery</h4>
                        </li>
                        <li>
                          <img src="/images/badminton.png" />
                          <h4>Badminton</h4>
                        </li>
                        <li>
                          <img src="/images/eightball.png" />
                          <h4>8 ball pool</h4>
                        </li>
                        <li>
                          <img src="/images/surfing.png" />
                          <h4>Surfing</h4>
                        </li>
                        <li>
                          <img src="/images/cricket.png" />
                          <h4>Cricket</h4>
                        </li>
                        <li>
                          <img src="/images/hockey.png" />
                          <h4>Hockey</h4>
                        </li>
                        <li>
                          <img src="/images/cycle.png" />
                          <h4>Cycling</h4>
                        </li>
                        <li>
                          <img src="/images/skating.png" />
                          <h4>Skating</h4>
                        </li>
                        <li>
                          <img src="/images/snooker.png" />
                          <h4>Snooker</h4>
                        </li>
                      </ul>
                      <ul
                        className="list-inline"
                        style={{ marginLeft: "16px" }}
                      >
                        <li>
                          <img src="/images/footballhero.png" />
                          <h4>Football</h4>
                        </li>
                        <li>
                          <img src="/images/gridiron.png" />
                          <h4>Gridiron Football</h4>
                        </li>
                        <li>
                          <img src="/images/basketball.png" />
                          <h4>Basketball</h4>
                        </li>
                        <li>
                          <img src="/images/archer.png" />
                          <h4>Archery</h4>
                        </li>
                        <li>
                          <img src="/images/badminton.png" />
                          <h4>Badminton</h4>
                        </li>
                        <li>
                          <img src="/images/eightball.png" />
                          <h4>8 ball pool</h4>
                        </li>
                        <li>
                          <img src="/images/surfing.png" />
                          <h4>Surfing</h4>
                        </li>
                        <li>
                          <img src="/images/cricket.png" />
                          <h4>Cricket</h4>
                        </li>
                        <li>
                          <img src="/images/hockey.png" />
                          <h4>Hockey</h4>
                        </li>
                        <li>
                          <img src="/images/cycle.png" />
                          <h4>Cycling</h4>
                        </li>
                        <li>
                          <img src="/images/skating.png" />
                          <h4>Skating</h4>
                        </li>
                        <li>
                          <img src="/images/snooker.png" />
                          <h4>Snooker</h4>
                        </li>
                      </ul>
                    </div>
                    <div className="anim-two">
                      <ul className="list-inline">
                        <li>
                          <img src="/images/snooker.png" />
                          <h4>Snooker</h4>
                        </li>
                        <li>
                          <img src="/images/skating.png" />
                          <h4>Skating</h4>
                        </li>
                        <li>
                          <img src="/images/cycle.png" />
                          <h4>Cycling</h4>
                        </li>
                        <li>
                          <img src="/images/hockey.png" />
                          <h4>Hockey</h4>
                        </li>
                        <li>
                          <img src="/images/cricket.png" />
                          <h4>Cricket</h4>
                        </li>
                        <li>
                          <img src="/images/surfing.png" />
                          <h4>Surfing</h4>
                        </li>
                        <li>
                          <img src="/images/eightball.png" />
                          <h4>8 ball pool</h4>
                        </li>
                        <li>
                          <img src="/images/badminton.png" />
                          <h4>Badminton</h4>
                        </li>
                        <li>
                          <img src="/images/archer.png" />
                          <h4>Archery</h4>
                        </li>
                        <li>
                          <img src="/images/basketball.png" />
                          <h4>Basketball</h4>
                        </li>
                        <li>
                          <img src="/images/gridiron.png" />
                          <h4>Gridiron Football</h4>
                        </li>
                        <li>
                          <img src="/images/footballhero.png" />
                          <h4>Football</h4>
                        </li>
                      </ul>
                      <ul
                        className="list-inline"
                        style={{ marginLeft: "16px" }}
                      >
                        <li>
                          <img src="/images/snooker.png" />
                          <h4>Snooker</h4>
                        </li>
                        <li>
                          <img src="/images/skating.png" />
                          <h4>Skating</h4>
                        </li>
                        <li>
                          <img src="/images/cycle.png" />
                          <h4>Cycling</h4>
                        </li>
                        <li>
                          <img src="/images/hockey.png" />
                          <h4>Hockey</h4>
                        </li>
                        <li>
                          <img src="/images/cricket.png" />
                          <h4>Cricket</h4>
                        </li>
                        <li>
                          <img src="/images/surfing.png" />
                          <h4>Surfing</h4>
                        </li>
                        <li>
                          <img src="/images/eightball.png" />
                          <h4>8 ball pool</h4>
                        </li>
                        <li>
                          <img src="/images/badminton.png" />
                          <h4>Badminton</h4>
                        </li>
                        <li>
                          <img src="/images/archer.png" />
                          <h4>Archery</h4>
                        </li>
                        <li>
                          <img src="/images/basketball.png" />
                          <h4>Basketball</h4>
                        </li>
                        <li>
                          <img src="/images/gridiron.png" />
                          <h4>Gridiron Football</h4>
                        </li>
                        <li>
                          <img src="/images/footballhero.png" />
                          <h4>Football</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                <div className="bottom-content">
                  <div className="scrolldown">
                    <img src="/images/scrolldown.png" />
                    Scroll Down
                  </div>
                  <div>
                    <ul className="list-inline">
                      {/* <li>
                        <Link to="">
                          <img src="/images/facebook.png" />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="" >
                          <img src="/images/twit.png" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src="/images/tele.png" />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="">
                          <img src="/images/in.png" />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src="/images/git.png" />
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="calculator">
                <div className="content">
                  <h2>Price Increase in</h2>
                  {
                    contractInfo.lastUpdateTime ? <Countdown
                      date={(() => {
                        const d = new Date(parseInt(contractInfo.lastUpdateTime));
                        for(let i = 7; ; i += 7) {
                          d.setDate(d.getDate() + i);
                          if(d.getTime() > new Date().getTime()) break;
                        }
                        return d;
                      })()}
                      renderer={renderer}
                    /> : <Skeleton style={{ marginBottom: '5px' }} height={79} />
                  }

                  <div className="progress">
                    <span>{millify(contractInfo.soldToken)} / {millify(TARGET_TOKEN_VALUE)} XER</span>
                    <ProgressBar
                      // now={50} 
                      now={calculatePercentage()}
                    />
                    {/* <div className="progress-inner"></div> */}
                  </div>
                  <div className="launch-price">
                    <h4>
                      Current Price = <span>0.05 USDT</span>
                    </h4>
                  </div>
                  <form className="calculateform">
                    <div className="form-group">
                      <div className="form-heading">
                        <label>I want to spend</label>
                      </div>

                      <div className="form">
                        <div>
                          <input
                            maxLength={10}
                            type="text"
                            value={inputValue}
                            className="form-control"
                            placeholder="Enter amount"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="select">
                          <div className="dropdown">
                            <div
                              className="dropdown-header"
                              onClick={toggleDropdown}
                            >
                              {selectedOption && (
                                <div className="dropdown-selected">
                                  <img
                                    src={selectedOption.image}
                                    alt={selectedOption.label}
                                  />
                                  <span>{selectedOption.label}</span>
                                  <i
                                    className={`dropdown-icon ${isOpen ? "open" : ""
                                      }`}
                                  >
                                    <img src="/images/calculatedrop.png" />
                                  </i>
                                </div>
                              )}
                            </div>
                            {isOpen && (
                              <div className="dropdown-list">
                                {options.slice(0, contractInfo?.prices?.length || 2).map((option) => (
                                  <div
                                    key={option.label}
                                    className="dropdown-item"
                                    onClick={() => handleOptionClick(option)}
                                  >
                                    <img
                                      src={option.image}
                                      alt={option.label}
                                    />
                                    <span>{option.label}</span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <div className="form">
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Limit 10 - 5000"
                          />
                        </div>
                        <div className="select">
                          <img src="/images/circle.png" />
                          <select className="form-control">
                            <option>USDT</option>
                          </select>
                        </div>
                      </div> */}
                    </div>
                    <div className="form-group">
                      <div className="form-heading">
                        <label>I will receive</label>
                        <label>
                          {/* {contractInfo?.prices[TokenPriceIndex[selectedOption.label]]}                         */}
                       
                          1 {selectedOption.label} = {
                            contractInfo?.prices[
                              TokenPriceIndex[selectedOption.label]
                            ]?.toString()} XER 




                        </label>
                      </div>
                      <div className="form">
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                            value={contractInfo.prices.length > 0 ?
                              Number.isNaN(inputValue * contractInfo?.prices?.[
                                TokenPriceIndex[selectedOption.label]
                              ]?.toString()) ? 0 : inputValue * contractInfo?.prices?.[
                                TokenPriceIndex[selectedOption.label]
                              ]?.toString() : 0} />


                          {/* // {Number.isNaN(inputValue / */}
                          {/* // (contractInfo.prices.length > 0 ?
                            //     contractInfo.prices[
                            //         TokenPriceIndex[selectedOption.value]
                            //       ]?.toString() : 0)) ? 0 : (inputValue /
                            //       (contractInfo.prices.length > 0 ?
                            //           contractInfo.prices[
                            //               TokenPriceIndex[selectedOption.value]
                            //             ]?.toString() : 0))}
                         
                          */}

















                        </div>
                        <div className="select">
                          <img src="/images/playpcircle.png" />
                          {/* <h4>PLAP</h4> */}
                        </div>
                      </div>
                    </div>
                    <div className="calculate-btn">
                      <button onClick={((e) => {
                        e.preventDefault();
                        if (!isConnected) document.getElementById('connect-btn').click();
                        else if (inputValue != 0 && inputValue) navigate(`/buy?amount=${inputValue}&currency=${selectedOption.value}`);
                        else navigate('/buy')
                      })} type="button" className="btn form-control">
                        Buy XER
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    // <div className="container">
    //   <div className="hero-gradient"></div>
    //   <div className="hero">
    //     <div className="row">
    //       <div
    //         className="col-lg-12  pb-5"
    //         data-aos="fade-right"
    //         data-aos-duration="1500"
    //       >
    //         <div className="d-flex align-items-center justify-content-center gap-3 p-2  news-main">
    //           <div className="news">News!</div>
    //           <span>
    //             Update new features for active users{" "}
    //             <svg
    //               width="16"
    //               height="16"
    //               viewBox="0 0 16 16"
    //               fill="none"
    //               xmlns="http://www.w3.org/2000/svg"
    //             >
    //               <path
    //                 d="M8.34093 15.6387C3.06787 15.6387 1.659 10.1419 2.29584 6.54041C2.32356 6.38366 2.52903 6.3385 2.61856 6.47012C2.89581 6.87762 3.14253 7.46622 3.55131 7.41512C4.16571 7.33831 4.59137 1.91681 8.23278 0.391343C8.34834 0.342936 8.46925 0.43478 8.45656 0.559405C8.2765 2.32606 9.33721 4.85528 11.2164 4.85528C12.2937 4.85528 13.0307 3.99553 13.3991 3.13209C13.5109 2.86981 13.8942 2.90287 13.9583 3.18072C14.6155 6.02703 16.3724 15.6387 8.34093 15.6387Z"
    //                 fill="#FF8F1F"
    //               />
    //               <path
    //                 d="M6.4924 10.3383C6.6164 10.2342 6.80465 10.3057 6.8328 10.4652C6.89868 10.8384 7.1153 11.4798 7.82586 11.4798C8.68061 11.4798 9.26068 9.12778 9.4128 8.43606C9.43783 8.32225 9.54874 8.24497 9.66293 8.26818C11.799 8.7025 12.8376 15.6387 8.14096 15.6387C4.72183 15.6387 5.49643 11.175 6.4924 10.3383ZM12.3293 2.82668C12.4046 2.23787 11.3297 3.84834 11.3297 3.84834C11.3297 3.84834 12.1492 4.2354 12.3293 2.82668ZM1.49886 11.608C1.52152 11.3573 1.19905 10.6862 1.14196 10.9519C1.0053 11.5877 1.47621 11.8587 1.49886 11.608Z"
    //                 fill="#FFB636"
    //               />
    //               <path
    //                 d="M10.0993 10.8936C10.0192 10.5596 9.73192 11.3355 9.76801 11.6516C9.80411 11.9676 10.2909 11.6929 10.0993 10.8936Z"
    //                 fill="#FFD469"
    //               />
    //             </svg>
    //           </span>
    //         </div>
    //         <h1 className="pt-4 pb-4 text-center">
    //           Revolutionizing Sports and Entertainment with PlayApp
    //         </h1>
    //         <p className="text-center">
    //           Revolution in Sports. Global, open, and fair, $PLAP is a next
    //           generation cryptocurrency that will bring <br /> the next billion
    //           people into Web 3.0, and make movement part of global GDP
    //         </p>
    //         <div className="d-flex flex-wrap w-100 gap-2 pt-5 justify-content-center">
    //           <Link to="/dashboard" style={{ textDecoration: "none" }}>
    //             {" "}
    //             <button className="bg-btn">Get Started</button>
    //           </Link>
    //           <button className="outline-btn">Learn More</button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Hero;
