import { Margin } from '@mui/icons-material';
import React, { useEffect } from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '10px',
    backgroundColor: 'black',
    border: 'none',
    borderRight: 'none',
    cursor:'pointer',
    // Hide the input
    '& input': {
      display: 'none'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '2px 6px',
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'white',
    cursor:'pointer'

  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    position: 'absolute',
    opacity: 0,
    right: '10px',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  menuList: (provided, state) => ({
    ...provided,
    color: 'white',
    margin:'10px'
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: 'black',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'white',
    cursor:'pointer'
  }),
};



export default function ImageDropdown(props) {
  const { options, onChangeListener } = props;
  const defaultOption = options[0]; // Set the default option
  useEffect(() => {
    // Add styles for hiding the input using attribute selector
    const inputStyles = `
      [class*="-Input"] {
        display: none !important;
      }
      .custom-option:hover {
        cursor: pointer;
        background-color:#ffffff1f !important;
        border-radius:5px;
      }
      .custom-option{
        padding:5px 10px;
      }
    `;

    // Append input styles to head
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(inputStyles));
    document.head.appendChild(styleElement);

    // Clean up function to remove injected styles when component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []); // Empty dependency array ensures this effect runs only once
  const getOptionProps = (option) => ({
    onMouseEnter: () => {
      document.body.style.cursor = 'pointer';
    },
    onMouseLeave: () => {
      document.body.style.cursor = 'auto';
    },
  });

  return (
    <Select
      defaultValue={defaultOption}
      onChange={onChangeListener}
      options={options}
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      styles={customStyles}
      getOptionProps={getOptionProps}
    />
  );
}

const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps} className='custom-option'>
    <img src={data.image} alt={label} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
    {label}
  </div>
);

const CustomSingleValue = ({ innerProps, label, data }) => (
  <div {...innerProps} className='d-flex align-items-center'>
    <img src={data.image} alt={label} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
    {label}
    <p style={{ marginLeft: '10px' }}>{data.label}</p>
  </div>
);
