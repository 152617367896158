import Modal from "react-bootstrap/Modal";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

export default (props) => {
    return (
      <Modal
        className="wallet-modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
            Choose your Network
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="wallet-main referral-main alert-main">
            <FaTimesCircle />
  
            {
              props.message.map((msg) => {
                return (
                  <>
                    <h4>{msg.title}</h4>
                    <p>
                      {msg.message}
                    </p>
                  </>
                )
              })
            }
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  