import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
const Teams = () => {
  return (
    <div className="teams" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <h4>Active founders</h4>
              <p>
                Listen to CEO and Directors Who are Crypto Experts: $XER is
                Gaining Buzz Online for All the Right Reasons.
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div style={{ position: "relative" }} className="teams-cards">
              <div>
                <img src="/images/team1.png" />
              </div>
              <div className="title">
                <h4>Doug Crosdale</h4>
                <p>CEO & Director</p>
              </div>
              <div className="social">
                <ul className="list-unstyled m-0">
                  <li>
                    <img src="/images/facebook.png" />
                  </li>
                  <li>
                    <img src="/images/twit.png" />
                  </li>
                  <li>
                    <img src="/images/in.png" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div style={{ position: "relative" }} className="teams-cards">
              <div>
                <img src="/images/team2.png" />
              </div>
              <div className="title">
                <h4>Hamza Yaseen</h4>
                <p>CFO & Director</p>
              </div>
              <div className="social">
                <ul className="list-unstyled m-0">
                  <li>
                    <img src="/images/facebook.png" />
                  </li>
                  <li>
                    <img src="/images/twit.png" />
                  </li>
                  <li>
                    <img src="/images/in.png" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faqs">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="content">
                <h2 className="pb-2">FAQ's</h2>
                <p>
                  Find answers to common queries about Hypsoverse the Museum of
                  Art. In case we missed something, Live & Email support is
                  always here!
                </p>
                <Link to="/faq" className="contact">
                  See More
                </Link>
              </div>
            </div>
            <div
              className="col-lg-8 pt-5 pt-lg-0"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <Accordion className="faq-accordion" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What security measures are in place to protect my
                    investment?
                  </Accordion.Header>
                  <Accordion.Body>
                    The platform employs blockchain technology, end-to-end
                    encrypted communication, smart contract audits, and
                    AI-driven threat detection to ensure the highest level of
                    security for your investments and transactions.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How does the Hypsoverse the Museum of Art integrate
                    Non-Fungible Tokens (NFTs)?
                  </Accordion.Header>
                  <Accordion.Body>
                    Artists can tokenize their artwork as NFTs. It allows users
                    to bid on to acquire unique digital art pieces. The
                    blockchain-backed system provides transparency and secure
                    ownership. Meanwhile, it empowers artists to benefit from
                    their sales.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    What makes Hypsoverse Metaverse Unique?
                  </Accordion.Header>
                  <Accordion.Body>
                    Our platform offers the best way to explore art globally. We
                    are breaking geographical barriers and providing exclusive
                    experience. AI-guided tours, avatar customization, and a
                    wide range of curated artwork are the features of the
                    Hypsoverse Museum of Art.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    How can I buy a $XER token?
                  </Accordion.Header>
                  <Accordion.Body>
                    To buy $XER, you have to participate in ICO. Instructions
                    are on the website. You can register and purchase XER tokens
                    with cryptocurrency. Make sure you read our legal
                    compliances to understand regulations.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>How $XER token is used?</Accordion.Header>
                  <Accordion.Body>
                    The XER Token is an ERC20-based digital asset. it is to
                    perform any transaction within the Hypsoverse Museum of Art.
                    Buying, selling, and bidding on digital artwork through it.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Why XER?</Accordion.Header>
                  <Accordion.Body>
                    Transactions within the blockchain ecosystem avoids the
                    inherent weakness of fiat currency, inflation caused by
                    interest rates. Therefore, price fluctuations occur only vis
                    a vis a cash equivalent. Swapping XER for Digital Artwork
                    such as NFTs preserves value. The owner can choose to swap
                    his blockchain assets for cash when and if he chooses.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
