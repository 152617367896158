import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePublicClient, useSwitchChain, useWriteContract } from "wagmi";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import "react-circular-progressbar/dist/styles.css";
import "react-tooltip/dist/react-tooltip.css";
// Animation
import Countdown from "react-countdown";
import ImageDropdown from "../Home/ImageDropdown";
import { useAccount, useReadContract } from "wagmi";
import { formatEther, formatUnits, parseUnits } from "viem";
import contractConfig from "../abi/contracts_config.json";
import { config, TARGET_TOKEN_VALUE } from "../../confiq";
import Modal from "react-bootstrap/Modal";

import axios from "axios";
import millify from "millify";
import { saveTransaction } from "../../api/api";
import { disconnect } from "@wagmi/core";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SuccessModal from "../layouts/Modals/SuccessModal";
import ErrorModal from "../layouts/Modals/ErrorModal";
const percentage = 56;

const MainLayout = () => {
  const { address, chain } = useAccount();
  const [totalTokenSolds, setTotalTokenSolds] = useState(null);
  const [totalTokenSupplys, setTotalTokenSupplys] = useState(null);
  const [inputValue, setInputValue] = useState(0);
  const [purchaseHash, setPurchaseHash] = useState("");
  const [ApprovalHash, setApprovalHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [hashModalOpen, setHashModalOpen] = useState(false);
  const [approvalSuccess, setApprovalSuccess] = useState(false); // State to track approval success
  const [showModal, setShowModal] = useState(false);
  const [getTransactionLists, setGetTransactionLists] = useState([]);
  const [isLoading, setApiIsLoading] = useState(true);
  const [modalSuccessShow, setSuccessModalShow] = useState([]);
  const [modalErrorShow, setErrorModalShow] = useState([]);

  const contract = contractConfig[chain?.id];

  const [searchParams] = useSearchParams();
  const [showFull, setShowFull] = useState(false);

  let storedChainId = localStorage.getItem("chainId");

  console.log("Stored Chain Name:", storedChainId);

  let add =
    address === undefined
      ? localStorage.getItem("chainId", storedChainId)
      : address;

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(address);
    toast.success("Address copied to clipboard!", {});
  };

  const TokenPriceIndex = {
    USDT: 0,
    WBTC: 1,
    BNB: 2,
    WBNB: 2,
    WETH: 1,
  };

  const SaleInfoKeys = {
    SOLD_TOKEN: 0,
    IS_SALE: 1,
    USDT_PRICE: 2,
    TOTAL_SALE_SUPPLY: 3,
    LAST_UPDATE_TIME: 4,
    SALE_START_TIME: 5,
    SALE_END_TIME: 6,
    BNB_RAISED: 7,
    USDT_RAISED: 8,
    WETH_RAISED: 9,
    REFFER_PERCENTAGE: 10,
  };

  const options = {
    USDT: {
      image: "/images/tether-usdt-logo-FA55C7F397-seeklogo.com.png",
      value: "USDT",
      label: "USDT",
    },
    WETH: { image: "/images/eth-logos.png", value: "WETH", label: "WETH" },
    BNB: {
      image: "/images/bnb-bnb-logo-removebg-preview.png",
      value: "BNB",
      label: "BNB",
    },
    WBNB: {
      image: "/images/bnb-bnb-logo-removebg-preview.png",
      value: "WBNB",
      label: "WBNB",
    },
    WBTC: {
      image: "/images/btc-log-removebg-preview.png",
      value: "WBTC",
      label: "WBTC",
    },
  };

  const optionsdrop = [
    { label: "USDT", image: "/images/usdt-removebg-preview.png" },
    { label: "BNB", image: "/images/bnb-bnb-logo.png" },
    {
      label: "WETH",
      image: "/images/ethereum-logo-EC6CDBA45B-seeklogo.com.png",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptiondrop, setSelectedOptiondrop] = useState(optionsdrop[0]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleOptionClick = (option) => {
    setSelectedOptiondrop(option);
    setSelectedOption(option.label);
    setIsOpen(false);
  };

  const [selectedOption, setSelectedOption] = useState("USDT");

  const handleInputChange = (event) => {
    setInputValue(event.target.value.replace(/[^0-9.]/g, ""));
  };

  const Completionist = () => <span>You are good to go!</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const formatTime = (value) => {
      return value < 10 ? `0${value}` : value;
    };

    if (saleInfo.data && saleInfo.data[SaleInfoKeys.IS_SALE] == false) {
      return <Completionist />;
    } else {
      return (
        <div className="d-flex mt-4 countdown justify-content-center">
          <div className="p-2">
            <h2 style={{ marginBottom: 0 }} className="time-num">
              {loading
                ? formatTime(Number.isNaN(days) ? 0 : days)
                : formatTime(Number.isNaN(days) ? 0 : days)}{" "}
            </h2>
            <label style={{ color: "white", marginTop: 0 }}>Days</label>
          </div>
          <div className="p-2">
            <h2 style={{ marginBottom: 0 }} className="time-num">
              {loading
                ? formatTime(Number.isNaN(hours) ? 0 : hours)
                : formatTime(Number.isNaN(hours) ? 0 : hours)}{" "}
            </h2>
            <label style={{ color: "white", marginTop: 0 }}>Hours</label>
          </div>
          <div className="p-2">
            <h2 style={{ marginBottom: 0 }} className="time-num">
              {loading
                ? // <Spinner animation="grow" />
                  formatTime(Number.isNaN(minutes) ? 0 : minutes)
                : formatTime(Number.isNaN(minutes) ? 0 : minutes)}{" "}
            </h2>
            <label style={{ color: "white", marginTop: 0 }}>Minutes</label>
          </div>
          <div className="p-2">
            <h2 style={{ marginBottom: 0 }} className="time-num">
              {loading
                ? // <Spinner animation="grow" />
                  formatTime(Number.isNaN(seconds) ? 0 : seconds)
                : formatTime(Number.isNaN(seconds) ? 0 : seconds)}
            </h2>
            <label style={{ color: "white", marginTop: 0 }}>Seconds</label>
          </div>
        </div>
      );
    }
  };

  const balanceOfToken = useReadContract({
    address: contract?.PLAPTOKEN?.address,
    abi: contract?.PLAPTOKEN?.abi,
    functionName: "balanceOf",
    args: [address],
    chainId: chain?.id,
  });

  const handleToggle = () => {
    setShowFull(!showFull);
  };
  const formattedBalance = balanceOfToken?.data
    ? formatEther(balanceOfToken?.data)
    : "0";

  const displayBalance = showFull
    ? formattedBalance + " XER"
    : parseFloat(formattedBalance).toFixed(3) + "..." + " XER";

  const totalTokenSold = useReadContract({
    address: contract?.ICO?.address,
    abi: contract?.ICO?.abi,
    functionName: "getSoldToken",
  });

  useEffect(() => {
    setTotalTokenSolds(totalTokenSold);
    setTotalTokenSupplys(totalTokenSupply);
  }, []);

  const saleInfo = useReadContract({
    address: contract?.ICO?.address,
    abi: contract?.ICO?.abi,
    functionName: "getSaleInfo",
  });

  const totalTokenSupply = useReadContract({
    address: contract?.ICO?.address,
    abi: contract?.ICO?.abi,
    functionName: "getTotalSaleSupply",
  });

  const prices = useReadContract({
    address: contract?.ICO?.address,
    abi: contract?.ICO?.abi,
    functionName: "getPrices",
  });

  const rewardTokens = useWriteContract();
  const publicClient = usePublicClient();

  useEffect(() => {
    if (searchParams.get("amount") && prices.data)
      setInputValue(searchParams.get("amount").replace(/[^0-9.]/g, ""));
    if (
      searchParams.get("currency") &&
      options[searchParams.get("currency").toUpperCase()] &&
      contract?.tokens
    ) {
      setSelectedOption(searchParams.get("currency").toUpperCase());
      setSelectedOptiondrop(
        options[searchParams.get("currency").toUpperCase()]
      );
    }
  }, [prices.data, contract?.tokens]);

  const {
    writeContractAsync,
    isLoading: isApproving,
    error: approvalError,
  } = useWriteContract();

  const handleApprove = async () => {
    setApprovalSuccess(true);
    setHashModalOpen(true);

    const approvalResponse = await writeContractAsync({
      address: contract?.tokens[selectedOption]?.address,
      abi: contract?.tokens[selectedOption]?.abi,
      functionName: "approve",
      args: [
        contract?.ICO?.address,
        inputValue * 10 ** contract?.tokens[selectedOption]?.decimals,
      ],
    });
    setApprovalSuccess(true);
    setApprovalHash(approvalResponse);

    return await publicClient.waitForTransactionReceipt({
      hash: approvalResponse,
    });
  };
  const purchasePlap = async () => {
    if (!inputValue || inputValue === 0) {
      toast.info(`Please enter the valid purchase amount`);
      return;
    }

    setLoading(true);

    try {
      if (!contract?.tokens[selectedOption].native) await handleApprove();
      const claim = await rewardTokens.writeContractAsync({
        address: contract?.ICO?.address,
        abi: contract?.ICO?.abi,
        functionName: contract.tokens[selectedOption].native
          ? "buyWithBNB"
          : "buy",
        args: contract.tokens[selectedOption].native
          ? [
              inputValue * 10 ** contract.tokens[selectedOption].decimals,
              returnValidReferer(),
            ]
          : [
              inputValue * 10 ** contract.tokens[selectedOption].decimals,
              contract.tokens[selectedOption].index, // selectedOption,
              returnValidReferer(),
            ],
        value: contract.tokens[selectedOption].native
          ? parseUnits(
              inputValue,
              contract.tokens[selectedOption].decimals
            ).toString()
          : undefined,
      });

      const trans = await publicClient.waitForTransactionReceipt({
        hash: claim,
      });

      setSuccessModalShow(
        // !contract?.tokens[selectedOption].native ? [ { title: 'Approval Hash', message: ApprovalHash }, { title: 'Purchase Hash', message: trans.transactionHash } ] :
        [{ title: "Purchase Hash", message: trans.transactionHash }]
      );

      const transactionData = {
        chain: chain.name,
        blockNumber: Number(trans.blockNumber),
        explorerURL: chain.blockExplorers.default.url,
        from: trans.from,
        to: trans.to,
        transactionAmount: inputValue,
        tokenPurchase:
          inputValue *
          prices?.data?.[TokenPriceIndex[selectedOption]].toString(),
        transactionType: "plap",
        transactionHash: trans.transactionHash,
      };

      await saveTransaction(transactionData);

      transactionList();
      setPurchaseHash(claim);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = /User rejected the request./.test(error.message)
        ? "User rejected the request."
        : error.shortMessage || error.message;
      setErrorModalShow([{ title: "Request Failed", message: errorMessage }]);
      // toast.info(errorMessage);
    }
  };

  console.log("approval hash ", modalSuccessShow);

  function copyToClipboard(address) {
    const input = document.createElement("input");

    input.value = `${process.env.REACT_APP_ROOT_URL}/#/buy?ref=${address}`;

    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand("copy");
    document.body.removeChild(input);
  }

  const calculatePercentage = () => {
    if (saleInfo?.data && saleInfo?.data[SaleInfoKeys.SOLD_TOKEN]) {
      const num =
        (saleInfo.data?.[SaleInfoKeys.SOLD_TOKEN].toString() /
          10 ** 18 /
          TARGET_TOKEN_VALUE) *
        100;
      return num.toFixed(0);
    } else {
      return 0;
    }
  };

  const transactionList = async () => {
    try {
      const { data } = await axios.get(
        `https://api.artfundings.com/transactionHistory/${address}`,
        { headers: { apikey: '1234' } }
      );
      setGetTransactionLists(data.data);
    } catch (error) {
      console.error("Error fetching transaction list:", error);
    }
    setApiIsLoading(false);
  };

  useEffect(() => {
    transactionList();
  }, []);

  const handleClick = async () => {
    if (typeof ethereum === "undefined") {
      console.log(
        "Ethereum provider not detected. Please install MetaMask or a similar wallet extension."
      );
      return;
    }

    const tokenAddress = contract?.PLAPTOKEN?.address;
    const tokenSymbol = "PLAT";
    const tokenDecimals = 18;
    const tokenImage = "http://placekitten.com/200/300";

    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
        toast.success("PlayApp token is added into your wallet.");
      } else {
        toast.info("Try again!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const returnValidReferer = () => {
    const referrerAddress = searchParams.get("ref");

    if (
      /^0x[a-fA-F0-9]{40}$/.test(referrerAddress)
      //&&
      // referrerAddress.toLowerCase() !== address?.toLowerCase()
    )
      return referrerAddress;
    return contract?.ICO?.address;
  };

  return (
    <div>
      <ToastContainer />
      {showModal && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className="wallet-modal"
          size="lg"
          style={{ maxWidth: "600px !important" }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {!ApprovalHash
                ? "Approval Proccesing"
                : "Purchase Processing" && !purchaseHash
                ? ""
                : "Purchase Procceed"}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="wallet-main " style={{ color: "white" }}>
              <div className="d-flex  gap-3">
                <div>
                  {!purchaseHash ? (
                    <Spinner animation="border" color="green" />
                  ) : (
                    ""
                  )}{" "}
                </div>
                <h1
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                  }}
                >
                  {!ApprovalHash
                    ? "Your approval has been processing.Please wait .."
                    : "Your purchase has been processing.Please wait .." &&
                      !purchaseHash
                    ? ""
                    : "Your Purchase Has been Succesfully Done "}
                </h1>
              </div>
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                {/* <div><Spinner style={{opacity:0}}/></div> */}
                <h1
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Tx Approval Hash
                </h1>{" "}
                <p
                  style={{ wordBreak: "break-all", textAlign: "center" }}
                  className="pt-2"
                >
                  {ApprovalHash}
                </p>
              </div>
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <h1
                  style={{
                    fontSize: "20px",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Tx Purchasing Hash
                </h1>{" "}
                <p
                  style={{ wordBreak: "break-all", textAlign: "center" }}
                  className="pt-2"
                >
                  {purchaseHash}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="main-content">
        <header className="d-flex justify-content-end align-items-center inner-content gap-2 gap-lg-5">
          <div className="d-flex align-items-center justify-content-end gap-2 gap-sm-4">
            <svg
              style={{ display: "none" }}
              width="33"
              height="34"
              viewBox="0 0 33 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.05"
                y="0.660565"
                width="32.9"
                height="32.9"
                rx="2.95"
                fill="url(#paint0_linear_2379_1950)"
                fill-opacity="0.4"
              />
              <rect
                x="0.05"
                y="0.660565"
                width="32.9"
                height="32.9"
                rx="2.95"
                stroke="#E0E0E0"
                stroke-width="0.1"
              />
              <path
                d="M21.1739 19.2764C21.1318 19.2256 21.0904 19.1748 21.0498 19.1258C20.4912 18.4502 20.1532 18.0424 20.1532 16.1297C20.1532 15.1395 19.9163 14.327 19.4494 13.7176C19.1051 13.2674 18.6397 12.9259 18.0263 12.6736C18.0184 12.6692 18.0113 12.6634 18.0054 12.6566C17.7848 11.9177 17.181 11.4228 16.5 11.4228C15.819 11.4228 15.2155 11.9177 14.9949 12.6558C14.989 12.6624 14.982 12.668 14.9743 12.6723C13.5428 13.2616 12.8471 14.3923 12.8471 16.129C12.8471 18.0424 12.5096 18.4502 11.9505 19.1251C11.9099 19.1741 11.8685 19.2238 11.8264 19.2756C11.7175 19.4069 11.6485 19.5667 11.6276 19.736C11.6067 19.9052 11.6347 20.077 11.7083 20.2308C11.865 20.5609 12.1989 20.7658 12.58 20.7658H20.4229C20.8022 20.7658 21.1338 20.5612 21.291 20.2326C21.3649 20.0787 21.3932 19.9068 21.3725 19.7374C21.3517 19.5679 21.2828 19.4079 21.1739 19.2764Z"
                fill="white"
              />
              <path
                d="M16.5 22.798C16.8669 22.7977 17.2269 22.6981 17.5418 22.5098C17.8566 22.3215 18.1147 22.0514 18.2885 21.7283C18.2967 21.7128 18.3007 21.6955 18.3003 21.678C18.2998 21.6605 18.2947 21.6434 18.2857 21.6284C18.2767 21.6134 18.2639 21.601 18.2486 21.5924C18.2334 21.5838 18.2161 21.5793 18.1986 21.5793H14.8019C14.7843 21.5792 14.7671 21.5837 14.7518 21.5923C14.7365 21.6009 14.7237 21.6133 14.7146 21.6283C14.7055 21.6433 14.7005 21.6604 14.7 21.6779C14.6995 21.6955 14.7035 21.7128 14.7117 21.7283C14.8855 22.0514 15.1435 22.3214 15.4584 22.5097C15.7732 22.6981 16.1331 22.7977 16.5 22.798Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2379_1950"
                  x1="0.586108"
                  y1="10.1071"
                  x2="32.8057"
                  y2="32.4078"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#4D4C50" />
                  <stop offset="1" stop-color="#2A282C" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                disconnect(config);
                localStorage.clear();
                window.location.href = "/";
              }}
              width="34"
              height="33"
              viewBox="0 0 34 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 16.5C6 16.8797 6.30783 17.1875 6.6875 17.1875H18.7779L15.1389 20.8264C15.01 20.9553 14.9376 21.1301 14.9376 21.3124C14.9375 21.6921 15.2453 21.9999 15.625 22C15.8074 22.0002 15.9823 21.9277 16.111 21.7986L20.9235 16.9861C20.9234 16.9862 20.9236 16.986 20.9235 16.9861C21.1918 16.7176 21.192 16.2823 20.9235 16.014L16.111 11.2015C15.8446 10.9442 15.4222 10.9442 15.1558 11.2015C14.8827 11.4653 14.8751 11.9005 15.1389 12.1736L18.7779 15.8125H6.6875C6.30783 15.8125 6 16.1203 6 16.5ZM24.5625 2.75H9.4375C7.53974 2.75176 6.00176 4.28974 6 6.1875V11.6875C6 12.0672 6.30783 12.375 6.6875 12.375C7.06717 12.375 7.375 12.0672 7.375 11.6875V6.1875C7.37626 5.04891 8.29891 4.12626 9.4375 4.125H24.5625C25.7011 4.12626 26.6237 5.04891 26.625 6.1875V26.8125C26.6237 27.9511 25.7011 28.8737 24.5625 28.875H9.4375C8.29891 28.8737 7.37626 27.9511 7.375 26.8125V21.3125C7.375 20.9328 7.06717 20.625 6.6875 20.625C6.30783 20.625 6 20.9328 6 21.3125V26.8125C6.00176 28.7103 7.53974 30.2482 9.4375 30.25H24.5625C26.4603 30.2482 27.9982 28.7103 28 26.8125V6.1875C27.9982 4.28974 26.4603 2.75176 24.5625 2.75Z"
                fill="#C13232"
              />
            </svg>
          </div>
        </header>
        <div className="row pt-3">
          <div className="col-xl-7">
            <div className="inner-content-dashboard">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="pb-2">My Wallet</h2>
                  <p style={{ wordBreak: "break-all" }}>{address}</p>
                  <div className="balance ">
                    <div className="inner-balance ">
                      <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div style={{ width: "87%" }}>
                          <p>Token Balance</p>
                          <h1>
                            {displayBalance}
                            <button
                              onClick={handleToggle}
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                            >
                              {showFull ? <FaEyeSlash /> : <FaEye />}
                            </button>
                            {/* {balanceOfToken?.data
                              ? formatEther(balanceOfToken?.data).substring(0, 20) + "..."  + " PLAP"
                              : "0 PLAP"} */}
                          </h1>
                        </div>
                        <div className="text-end">
                          <div></div>
                          <div>
                            <svg
                              width="61"
                              height="20"
                              viewBox="0 0 61 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 8.2C1.70238 11.8 4.5 18.5 11 18C17.5 17.5 18.2786 1 29.6571 1C41.0357 1 41.0357 20.0286 60 3.57143"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="mt-3">
                          <label style={{ color: "#fff" }}>Invest with:</label>

                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center gap-2 mt-2 flex-wrap">
                              <div>
                                <div className="d-flex align-items-center gap-3 flex-wrap">
                                  <div>
                                    <div>
                                      <img
                                        src="/images/ethereum-logo-EC6CDBA45B-seeklogo.com.png"
                                        alt="eth"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      <img
                                        src="/images/bnb-bnb-logo.png"
                                        alt="bnb"
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <div>
                                      <img
                                        src="/images/usdt-removebg-preview.png"
                                        alt="bnb"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div
                          style={{ display: address ? "" : "none" }}
                          className="mt-3 Referral-code"
                        >
                          <label style={{ color: "#fff" }}>
                            Referral code:
                          </label>

                          <div class="form mt-1">
                            <div style={{ width: "50%" }}>
                              <h4
                                style={{
                                  width: "50%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {address}
                              </h4>
                            </div>
                            <div>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  copyToClipboard(address);
                                }}
                                className="btn"
                              >
                                Copy
                              </button>
                              {/* <button className="btn" onClick={handleCopyAddress}>Copy</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8"></div>
              </div>
            </div>
            <div className="inner-content-dashboard mt-4">
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <h2 className="pb-3">Transaction History</h2>
                    <p className="d-flex align-items-center gap-1">
                      {" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.1543 2.27328C4.82035 2.27328 2.10791 4.98573 2.10791 8.31967C2.10791 11.6536 4.82035 14.3661 8.1543 14.3661C11.4882 14.3661 14.2007 11.6536 14.2007 8.31967C14.2007 4.98573 11.4882 2.27328 8.1543 2.27328ZM11.301 6.29326L7.39414 10.9443C7.35128 10.9954 7.29796 11.0366 7.23777 11.0653C7.17759 11.0939 7.11195 11.1093 7.04531 11.1103H7.03746C6.97227 11.1103 6.90781 11.0966 6.84827 11.07C6.78872 11.0435 6.73542 11.0047 6.69183 10.9562L5.01744 9.09582C4.97492 9.05072 4.94184 8.99757 4.92015 8.9395C4.89846 8.88143 4.8886 8.81962 4.89114 8.75768C4.89368 8.69575 4.90858 8.63494 4.93496 8.57885C4.96133 8.52275 4.99865 8.4725 5.04473 8.43103C5.09081 8.38956 5.14471 8.35773 5.20326 8.33739C5.26182 8.31706 5.32385 8.30863 5.38571 8.31261C5.44757 8.31659 5.50801 8.33289 5.56348 8.36056C5.61895 8.38823 5.66832 8.42671 5.70871 8.47374L7.02525 9.9365L10.5888 5.69502C10.6688 5.6026 10.7819 5.54535 10.9037 5.53565C11.0255 5.52594 11.1462 5.56456 11.2398 5.64315C11.3333 5.72174 11.3922 5.83399 11.4037 5.95565C11.4151 6.0773 11.3783 6.19857 11.301 6.29326Z"
                          fill="#01B574"
                        />
                      </svg>
                      {getTransactionLists.length} done{" "}
                    </p>
                  </div>
                  <div className="table-responsive pt-3">
                    <table>
                      <thead>
                        <tr>
                          <th>Tx.Hash</th>
                          <th>Coin(s) Purchase</th>
                          <th>Chain</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getTransactionLists &&
                          Array.isArray(getTransactionLists) &&
                          getTransactionLists.map((data, index) => (
                            <tr
                              key={index} // Make sure to provide a unique key when using map
                              style={{
                                borderBottom:
                                  "1px solid  rgba(255, 255, 255, 0.40)",
                              }}
                            >
                              <td>
                                <a
                                  style={{ color: "white" }}
                                  href={`${data.explorerURL}/tx/${data.transactionHash}`}
                                  target="_blank"
                                >
                                  {data?.transactionHash.slice(0, 5)}...
                                  {data?.transactionHash.slice(-5)}{" "}
                                </a>
                              </td>
                              {/* <td>
                                {" "}
                                <a style={{ color: 'white' }} href={`${data.explorerURL}/address/${data.from}`} target="_blank">
                                  {data?.from.slice(0, 5)}...
                                  {data?.from.slice(-5)}{" "}
                                </a>
                              </td> */}
                              <td>{data?.tokenPurchase} XER</td>
                              <td>{data?.chain}</td>
                              <td>
                                <svg
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                ></svg>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <>
                      {!isLoading && getTransactionLists.length === 0 ? (
                        <h6 style={{ color: "white", marginTop: 10 }}>
                          No transactions done yet
                        </h6>
                      ) : null}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 pt-3 pt-xl-0">
            <section className="Hero dashboardhero">
              <div className="calculator">
                <div className="content">
                  <h2>Price Increase in</h2>
                  {saleInfo?.data ? (
                    <Countdown
                      date={(() => {
                        const d = new Date(
                          parseInt(
                            saleInfo?.data?.[SaleInfoKeys.LAST_UPDATE_TIME]
                          ) * 1000
                        );
                        for (let i = 7; ; i += 7) {
                          d.setDate(d.getDate() + i);
                          if (d.getTime() > new Date().getTime()) break;
                        }
                        return d;
                      })()}
                      renderer={(props) => {
                        if (props.completed) {
                          return (
                            <h4
                              className="text-center countdown pt-4 pb-4 mt-3 mb-3 font-bold "
                              style={{ fontWeight: "700", color: "white" }}
                            >
                              {" "}
                              Sale is concluded
                            </h4>
                          );
                        } else {
                          return renderer(props);
                        }
                      }}
                    />
                  ) : (
                    <Skeleton style={{ marginBottom: "5px" }} height={79} />
                  )}
                  <div className="progress">
                    <span>
                      {saleInfo.data
                        ? millify(
                            saleInfo.data[SaleInfoKeys.SOLD_TOKEN]?.toString() /
                              10 ** 18
                          )
                        : 0}{" "}
                      / {millify(TARGET_TOKEN_VALUE)} XER
                    </span>
                    <ProgressBar
                      // now={50}
                      now={calculatePercentage()}
                    />
                  </div>
                  <div className="launch-price">
                    <h4>
                      Current Price = <span>0.05 USDT</span>
                    </h4>
                  </div>
                  <div class="mt-3 Referral-code">
                    <div class="form mt-1">
                      <div style={{ width: "50%" }}>
                        <h4
                          style={{
                            width: "50%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {returnValidReferer()}
                        </h4>
                      </div>
                      {/* <div>
                        <h4>{returnValidReferer()}</h4>
                      </div> */}
                      <div>
                        <button class="btn">Referrer</button>
                      </div>
                    </div>
                  </div>
                  <form className="calculateform">
                    <div className="form-group">
                      <div className="form-heading">
                        <label>I want to spend</label>
                      </div>
                      <div className="form">
                        <div className="w-100">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter amount"
                            onChange={handleInputChange}
                            value={inputValue || ""}
                          />
                        </div>
                        <div className="select">
                          <div className="dropdown">
                            <div
                              className="dropdown-header"
                              onClick={toggleDropdown}
                            >
                              {selectedOptiondrop && (
                                <div className="dropdown-selected">
                                  <img
                                    src={selectedOptiondrop.image}
                                    alt={selectedOptiondrop.label}
                                  />
                                  <span>{selectedOptiondrop.label}</span>
                                  <i
                                    className={`dropdown-icon ${
                                      isOpen ? "open" : ""
                                    }`}
                                  >
                                    <img src="/images/calculatedrop.png" />
                                  </i>
                                </div>
                              )}
                            </div>
                            {isOpen && (
                              <div className="dropdown-list">
                                {Object.keys(contract?.tokens || {}).map(
                                  (key) => {
                                    const option = options[key];
                                    return (
                                      <div
                                        key={option.label}
                                        className="dropdown-item"
                                        onClick={() =>
                                          handleOptionClick(option)
                                        }
                                      >
                                        <img
                                          src={option.image}
                                          alt={option.label}
                                        />
                                        <span>{option.label}</span>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-heading">
                        <label>I will receive</label>
                        <label>
                          1 {selectedOptiondrop.label} ={" "}
                          {prices?.data?.[
                            TokenPriceIndex[selectedOption]
                          ]?.toString()}{" "}
                          XER{" "}
                        </label>
                      </div>
                      <div className="form">
                        <div className="w-100">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Limit 10 - 5000"
                            readOnly
                            value={
                              prices?.data
                                ? inputValue *
                                  prices?.data?.[
                                    TokenPriceIndex[selectedOption]
                                  ]?.toString()
                                : 0
                            }
                          />
                        </div>
                        <div className="select">
                          <img src="/images/playpcircle.png" />
                          {/* <h4>PLAP</h4> */}
                        </div>
                      </div>
                    </div>
                    <div className="calculate-btn">
                      <button
                        onClick={purchasePlap}
                        type="button"
                        className="btn form-control"
                      >
                        {loading ? <Spinner animation="border" /> : "Buy XER"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
            <div
              className="inner-content-dashboard mt-4"
              style={{ cursor: "pointer" }}
              key={chain?.id}
              onClick={handleClick}
            >
              <div className="meta-mask d-flex align-items-center gap-4">
                <img src="/images/Metamask.png" alt="" />
                <h2>
                  <h2>Add XER To {chain?.nativeCurrency?.symbol} Network</h2>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-xl-5 pt-3 pt-xl-0" style={{ display: "none" }}>
            <div className="hero">
              <div className="launch-card">
                <p>This round ends in</p>
                <div className="timer">
                  <Countdown
                    date={
                      new Date(
                        parseInt(saleInfo?.data?.[SaleInfoKeys.SALE_END_TIME]) *
                          1000
                      )
                    }
                    renderer={(props) => {
                      if (props.completed) {
                        return (
                          <div
                            className="text-center countdown pt-4 pb-4 mt-3 mb-3 font-bold "
                            style={{ fontWeight: "700" }}
                          >
                            {" "}
                            Your time is over
                          </div>
                        );
                      } else {
                        return renderer(props);
                      }
                    }}
                  />
                </div>
                <div className="pt-3 pb-3">
                  <div className="d-flex align-items-center justify-content-between ">
                    <p>Presale Stage 1</p>
                    <span className="d-flex align-items-center gap-2">
                      RAISED{" "}
                      <p style={{ color: "#91EB4A" }}>
                        {" "}
                        $
                        {millify(
                          saleInfo.data
                            ? formatEther(
                                saleInfo.data?.[SaleInfoKeys.SOLD_TOKEN]
                              )
                            : "0"
                        ) + " "}
                      </p>
                    </span>
                  </div>
                  <div className="text-end">
                    <span>Target : ${millify(TARGET_TOKEN_VALUE) + " "}</span>
                  </div>
                  <div>
                    <p className="text-center">1 XER</p>
                    <h1 className="text-center pt-3">$ 0.05</h1>
                  </div>
                  <div className="row pt-3 relative">
                    <div className="col-lg-6 pb-3 pb-lg-0 ">
                      <div className="card-payment">
                        <h4>Select a Payment Method</h4>
                        <h4 className="pt-2">
                          {chain?.nativeCurrency?.symbol}
                        </h4>
                        <div className="mt-2">
                          <ImageDropdown
                            options={Object.keys(contract?.tokens || {}).map(
                              (key) => options[key]
                            )}
                            onChangeListener={({ value }) =>
                              setSelectedOption(value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 pb-3 pb-lg-0 ">
                      {" "}
                      <div className="card-payment">
                        <h4 className="">
                          {selectedOption} AMOUNT <br />
                        </h4>
                        <h4 className="pt-2 ">
                          ${" "}
                          {prices?.data
                            ? formatUnits(
                                prices?.data?.[TokenPriceIndex[selectedOption]],
                                contract?.tokens[selectedOption].decimals
                              ).toString()
                            : "-"}
                        </h4>
                        <input
                          placeholder="0"
                          type="text"
                          className="usdt"
                          value={inputValue}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-lg-12 pb-3 pb-lg-0 ">
                      {" "}
                      <div className="card-payment">
                        <h4>BALANCE</h4>
                        <h4 className="pt-2">
                          {balanceOfToken.data
                            ? formatEther(balanceOfToken.data) + " XER"
                            : "0 XER"}
                        </h4>
                        <div className="scape">
                          <svg
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2356_5722)">
                              <path
                                d="M7.86831 4.26538C8.20989 4.23416 8.55339 4.24313 8.89578 4.24175C11.4922 4.24175 14.0886 4.23986 16.6847 4.24121C16.6842 5.03101 16.6837 5.82109 16.685 6.61087C13.945 6.61195 11.2047 6.60978 8.46467 6.61141C7.7182 6.61141 6.94361 6.78028 6.33534 7.23397C5.90557 7.54808 5.6051 8.01887 5.45717 8.5282C5.3514 8.88279 5.31111 9.25393 5.30948 9.62316C4.52273 9.62018 3.73595 9.62316 2.94946 9.62154C2.95136 8.81355 3.08469 7.99743 3.41168 7.25514C3.79355 6.37058 4.4524 5.61311 5.26675 5.10378C6.04731 4.61264 6.95389 4.34384 7.86831 4.26538Z"
                                fill="white"
                              />
                              <path
                                d="M10.2259 8.90071C10.9359 8.89908 11.6458 8.89881 12.3558 8.90071C12.3512 9.56398 12.2552 10.2316 12.0163 10.8522C11.7094 11.6657 11.1528 12.3811 10.4387 12.8741C9.57328 13.4787 8.51064 13.7589 7.46343 13.7668C4.97545 13.7668 2.48777 13.766 9.15527e-05 13.766C0.0025257 13.053 0.00144391 12.3403 0.00036207 11.6274C2.31659 11.6285 4.63279 11.6274 6.94874 11.6268C7.25489 11.6225 7.56241 11.6336 7.86695 11.5967C8.40517 11.534 8.94419 11.357 9.36989 11.0116C9.66686 10.774 9.88998 10.4504 10.0255 10.0956C10.1707 9.71602 10.2281 9.30605 10.2259 8.90071Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2356_5722">
                                <rect
                                  width="16.6849"
                                  height="16.6849"
                                  fill="white"
                                  transform="translate(0.00012207 0.69519)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <h4>
                            {" "}
                            {0.05 *
                              (
                                inputValue *
                                (prices?.data
                                  ? formatUnits(
                                      prices?.data?.[
                                        TokenPriceIndex[selectedOption]
                                      ],
                                      contract.tokens[selectedOption].decimals
                                    ).toString()
                                  : 0)
                              ).toFixed(18)}{" "}
                            XER
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="connect-btn" onClick={purchasePlap}>
                  {loading ? <Spinner animation="border" /> : "Purchase"}
                </button>
              </div>
            </div>
            <div
              className="inner-content-dashboard mt-4"
              style={{ cursor: "pointer" }}
              key={chain?.id}
              onClick={handleClick}
            >
              <h2 className="pb-2">Network</h2>
              <div className="meta-mask d-flex align-items-center gap-4">
                <img src="/images/Metamask.png" alt="" />
                <h2>Add XER To {chain?.nativeCurrency?.symbol} Network</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        show={modalSuccessShow.length > 0}
        message={modalSuccessShow}
        onHide={() => setSuccessModalShow([])}
      />
      <ErrorModal
        show={modalErrorShow.length > 0}
        message={modalErrorShow}
        onHide={() => setErrorModalShow([])}
      />
    </div>
  );
};

export default MainLayout;
